<template>
    <div class="course-container">
        <div class="common-first-part">
            <div>
                <p>课程建设</p>
                <span>校本教材建设、专业核心课程建设、精品课程建设</span>
            </div>
            <img :src="require('@/assets/images/index/course-topbanner.png')" alt="">
        </div>
        <div class="second-part">
            <p class="common-title">校本教材建设</p>
            <span>依据专业人才培养方案，进行校本教材建设：</span>
            <span>按照编写提纲、教材样章、编辑加工、教材初稿、专家审阅、三审三校的步骤，各流程配备</span>
            <span>专业人员，确保教材质量和专业性。</span>
            <el-carousel indicator-position="outside" arrow="always">
                <el-carousel-item>
                    <div class="slide-items">
                        <img :src="require('@/assets/images/index/slide-1.png')" alt="">
                        <img :src="require('@/assets/images/index/slide-2.png')" alt="">
                        <img :src="require('@/assets/images/index/slide-3.png')" alt="">
                        <img :src="require('@/assets/images/index/slide-4.png')" alt="">
                        <img :src="require('@/assets/images/index/slide-5.png')" alt="">
                        <img :src="require('@/assets/images/index/slide-6.png')" alt="">
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="slide-items">
                        <img :src="require('@/assets/images/index/book-1.png')" alt="">
                        <img :src="require('@/assets/images/index/book-2.png')" alt="">
                        <img :src="require('@/assets/images/index/book-3.png')" alt="">
                        <img :src="require('@/assets/images/index/book-4.png')" alt="">
                        <img :src="require('@/assets/images/index/book-5.png')" alt="">
                        <img :src="require('@/assets/images/index/book-6.png')" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="third-part">
            <img :src="require('@/assets/images/index/course-1.png')" alt="">
            <div>
                <p class="common-title">专业核心课程建设</p>
                <span>依据国家金课标准，进行专业核心课程建设：</span>
                <span>教学设计、教学资源制作和学业评价标准设计，并组织相关专家论证。</span>
            </div>
        </div>
        <div class="forth-part">
            <div>
                <p class="common-title">精品课程建设</p>
                <span>组建建设团队，制定建设方案，借助数字教学平台等技术，建成体系完整、质量高的精品课程。</span>
            </div>
            <img :src="require('@/assets/images/index/course-2.png')" alt="">
        </div>
    </div>
</template>
<script>
export default {
    components: {  },
    props: {},
    data() {
        return {
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.course-container {
    .second-part {
        margin: 16px 0;
        text-align: center;
        &>p {
            padding: 30px 0 24px;
        }
        &>span {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
            display: block;
        }
        .slide-items {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &>img {
                width: 132px;
                height: 182px;
                margin: 0 18px;
            }
        }
    }
    .third-part {
        height: 440px;
        background: #F6F9FD;
        padding: 100px 130px 120px 168px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 16px;
        &>img {
            height: 220px;
        }
        &>div {
            width: 440px;
            &>p {
                margin-bottom: 24px;
            }
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                display: block;
            }
        }
    }
    .forth-part {
        height: 440px;
        padding: 70px 237px 70px 123px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 60px;
        &>img {
            height: 300px;
        }
        &>div {
            width: 440px;
            &>p {
                margin-bottom: 24px;
                text-align: right;
            }
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                display: block;
            }
        }
    }
}
</style>